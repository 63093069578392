import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import CustomCard from '../../components/CustomCard/CustomCard.js';
import { withPrefix } from 'gatsby-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Row className="resource-card-group" mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm style={{
        marginBottom: '1px'
      }} mdxType="Column">
    <CustomCard title="Customize player branding" href={withPrefix('/channel-api-white-label-broadcasting/customize-player-branding')} content="Control the branding of the player by uploading your own logo and removing the IBM watermark." mdxType="CustomCard"></CustomCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterSm style={{
        marginBottom: '1px'
      }} mdxType="Column">
    <CustomCard title="Hide viewer numbers in player" href={withPrefix('/channel-api-white-label-broadcasting/hide-viewer-numbers-in-player')} content="The current and total number of viewers of a live channel will not be visible in the player." mdxType="CustomCard"></CustomCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <CustomCard title="Hide channel page" href={withPrefix('/channel-api-white-label-broadcasting/hide-channel-page')} content="If page lock is enabled, your channel and videos will not be found anywhere on video.ibm.com. Viewers will only be able to watch embedded players on external websites. " mdxType="CustomCard"></CustomCard>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      